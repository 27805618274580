$(document).ready(function(){
	$('.comment_writeanswer > a').click(function(){
		//$('#comment_form').detach().prependTo($(this).parents('.comment_content').next());
		$("input[name='parent']").val($(this).attr('data'));
		return false;
	});
	$('.comment_form_close > a').click(function(){
		$('#comment_form').detach().prependTo('.comment_form_block_main');
		return false;
	});
	if($("input[name='access']").length > 0){
		$("input[name='access']").val('up');
	}
	
});
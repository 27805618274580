
function carouselSlick() {
	$('#slot_slider').slick({              //слайдер на странице "игра"
        infinite: true,
        arrows: true,
        speed: 500,
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 8,
        slidesToScroll: 1,
		autoplay: true,
        prevArrow: '.prev_slide',
        nextArrow: '.next_slide',
        responsive: [
            {
                breakpoint: 641,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    });
}
function controlGameList() {
	if ($('#frame-box').length > 0) {
		 var parent = $('#frame-box .win-gallery-list');
		  var idtimer = setInterval(function(){
		  	var first_li = parent.find('li:first-child');
		    first_li.animate({marginTop: "-67px"}, 2000, function() {
			   first_li.detach();
			   parent.append(first_li);
			   $(first_li).css({marginTop: "0px"});
			  });
		  }, 5000);		  
  }
 }
function controlMobHeadSlider() {
	if ($('#mob_slider').length > 0 ){
		$('#mob_slider').owlCarousel({
			slideSpeed : 300,
			paginationSpeed : 400,
			singleItem:true,
			autoplay:true,
			autoplayTimeout:1000,
			autoplayHoverPause:true,
			loop:true
		});
	}
}

function controlMobGamesSlider() {
		$('#games_owl').owlCarousel({
			slideSpeed : 300,
			paginationSpeed : 400,
			pagination: false,
			navigation: true, 
			singleItem:true,
			autoHeight: true, 
			loop:true
		});
}

function controlMobClubsSlider() {
	if ($('.mob_gallary').length > 0 ){
		$('.mob_gallary').owlCarousel({
			slideSpeed : 300,
			paginationSpeed : 400,
			singleItem:true,
			autoplay:true,
			autoplayTimeout: 1000,
			autoplayHoverPause:true,
			pagination: false,
			navigation: true, 
			loop:true
		});
	}
}



function getOldComments() {
	$('#pag span').click(function(){
			$('#pag span').removeClass('cur_act');
			$(this).addClass('cur_act');
			var nc = $(this).text();
			$.post("/actions/oc.php", { nc: nc }, function(data) {
				$('.comment_box').html(data);	
            });
	});
}

function hideBtn(){
	$('#btn_top').click(function(){	
		$('#btn_top').css("opacity",0);
		$('body,html').animate({
			scrollTop: 0
		},'fast');
		return false;
	});	
}
function flyBtnControl(w){
	if ( w >= 200){
		$('#btn_top').css("opacity",1);
    }else{
		$('#btn_top').css("opacity",0);
	}	
}

function getSizeGameBox(){
		var game_box = $('#game_box');
		var w = $(window).width();
		if ( w < 840){
			var h = (w/1.33);
			$(game_box).height(h); 
		}
	
}

function sendForm(e){
	if ($(e).length > 0){
		$(e).submit(function(){
			var d = $(this).serialize();
			$.post("/actions/oc.php", { d }, function(data) {
				$('.title-form').addClass('sc').text('Спасибо! Ваше сообщение отправлено');
				var i = setTimeout(
					function() {
						$('.title-form').removeClass('sc').text('Написать нам письмо');
					}
				, 3000);	            
			});	
            return false;	
		});
	}
}

function switchMenu(){
	$('#btn_menu').click(function(){
		if ($('html').hasClass('act')) {
			$('html').removeClass('act');
			$('.menu').slideUp('slow');
		}else{
			$('html').addClass('act');
			$('.menu').slideDown('slow');
		}

		return false;
	});
}

$(document).ready(function() {
	var l = $('#mfftaRW8');
	sendForm(l);
	var s = $('.temp');
	sendForm(s);
	getSizeGameBox();
	getOldComments();
  	controlMobGamesSlider();
  	carouselSlick();
	controlGameList();
	hideBtn();
	switchMenu();
	controlMobHeadSlider();
	controlMobClubsSlider();
	$(window).scroll(function(){
		
		var w = $(window).scrollTop();
		flyBtnControl(w);
	});
});

var gamesUrl = 'https://slotsplay.app/game_json';

function loadIframeError(is_mobile, iframeBlock) {
        iframeBlock.hide();
}

function adBlock() {
    return false;
}

function iframeSize(ratio) {

	ratio = ratio ? ratio : 1.33;

	var iframeWin = $('.iframe-block-back');

	if (iframeWin.length > 0) {
    	iframeWin.width('100%');
		iframeWin.height(iframeWin.width() / ratio);
	}
}


function gameLoadMobile(data, iframeBlock) {

	var error = 0;
		
	if (data.mobile === undefined || data.mobile.direct_url === undefined) {
		error = 1;
		return error;		
	}

    $('.btn-startgame').attr('href', data.mobile.direct_url);
    $('.btn-startgame').attr('target', '_blank');
	$('.btn-startgame').text('Играть');

	$('.btn-startgame').on('click', function() {
		
		
		
	});

	return error;
}

function gameLoadDesktop(data, iframeBlock) {

	var error = 0;

	if (data.frame === undefined) {
		error = 1;
		return error;		
	}

	$('.btn-startgame').text('Играть');

	$('.btn-startgame').on('click', function() {

		iframeBlock.html(data.frame);
		iframeSize(eval(data.dimensions.replace(":","/")));

		animateLoader();


		return false;
	});
	
	return error;
	
}

function animateLoader() {
    
    var loaderWidth = $('.loader-wrap').width();
    
    $('.btn-startgame').hide();
    $('.iframe-loader').show();
    $('.loading-process').animate({
        width: loaderWidth
    }, 5000);
    
    return false;

}


function gameLoad() {

	var iframeBlock = $('.iframe-block-back');

	var iframeWidth =  parseInt(iframeBlock.width());
	var iframeHeight = parseInt(iframeBlock.height());
	var mobile = iframeBlock.data('mobile');

    // Waiting for good params:
    if (iframeWidth < 100 || iframeHeight < 100) {
        
        console.log('skipping…');
        
        setTimeout(function() { 
            gameLoad();
        }, 1000);
        
        return false;
    }


	var params = {
		name:  iframeBlock.data('name'),
		country: 'AUTO',
		width: iframeWidth,
		height: iframeHeight,
		is_casino: 1,
		has_adblock: adBlock()
	};

	$.ajax({
		type: 'POST', // sending as post
		url: gamesUrl, // games Handler
		dataType: "json",

		xhrFields: {
		    withCredentials: true
		},

		data: params, // дaнныe для oтпрaвки
		success: function(data) {
    		
    		
			var errorIframe = 0;

			if (mobile) {
				errorIframe = gameLoadMobile(data, iframeBlock);
			} else {
				errorIframe = gameLoadDesktop(data, iframeBlock);
			}
			
			if (errorIframe == 1) {
				loadIframeError(mobile, iframeBlock);
			}
			
		}

	}).fail(function() {			
		loadIframeError(mobile, iframeBlock);
	});	
}

    gameLoad();

